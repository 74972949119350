<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Sicht </template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/orders')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-2">
        <div class="mb-3">
          <label for="code" class="form-label"> # </label>

          <input
            class="form-control"
            id="code"
            name="code"
            :value="$codePadding(body.id)"
            disabled
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label">Typ</label>

          <b-form-select v-model="body.sexType" class="mb-3" disabled>
            <b-form-select-option value="MR">MR</b-form-select-option>
            <b-form-select-option value="MRS">MRS</b-form-select-option>
          </b-form-select>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="fname" class="form-label">Vorname</label>
          <input
            disabled
            type="text"
            class="form-control"
            id="fname"
            v-model="body.fname"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="lname" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="lname"
            v-model="body.lname"
            disabled
          />
        </div>
      </div>

      <div class="col-md-10">
        <div class="mb-3">
          <label for="address" class="form-label"> AddressHome </label>
          <input
            type="text"
            class="form-control"
            id="address"
            disabled
            v-model="body.address"
          />
        </div>
      </div>

      <div class="col-md-2">
        <div class="mb-3">
          <label for="zip" class="form-label"> PLZ </label>
          <input
            type="number"
            class="form-control"
            id="zip"
            disabled
            v-model.number="body.zip"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="city" class="form-label"> Stadt </label>
          <input
            type="text"
            class="form-control"
            id="city"
            v-model="body.city"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="country" class="form-label">Land</label>
          <input
            type="text"
            class="form-control"
            id="country"
            v-model="body.country"
            disabled
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="phone" class="form-label">Phone</label>

          <vue-tel-input
            v-model.trim="body.phone"
            v-bind="{
              mode: 'international',
            }"
            :allCountries="allCountries"
            disabled
          ></vue-tel-input>
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="validationTooltip04">Pay Type</label>
          <input
            class="form-control"
            id="paymentMethod"
            name="paymentMethod"
            :value="body.paymentMethod == 'cash' ? 'Cash' : 'Online'"
            disabled
          />
          <!-- <Button
            v-if=" body.paymentDone == 0"
            label="Change to Cash"
            class="p-button-warning mr-2 ml-2"
            @click="updateBaytype()"
          /> -->
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="paymentDone" class="form-label"> Bucht Fertig </label>

          <input
            class="form-control"
            id="paymentDone"
            name="paymentDone"
            :value="body.paymentDone ? 'Erledigt' : '--'"
            disabled
            v-if="body.paymentDone"
          />
          <Button
            v-else
            icon="pi pi-plus"
            class="p-button-info mr-2 ml-2"
            @click="updateDone()"
          />
        </div>
      </div>

      <br />

      <div class="col-md-12">
        <div class="mb-3">
          <label for="items" class="form-label">Artikel</label>
          <a
            class="list-group-item list-group-item-action"
            v-for="item in list"
            :key="item.id"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1" v-if="item.item">
                <img
                  :src="$baseUploadURL + item.item.image"
                  style="width: 50px"
                  alt=""
                />

                {{ item.item.name }}
                <br />
                <small> {{ item.item.code }} -{{ item.item.size }} </small>
              </h5>
              <h5 class="mb-1" v-else>
                Item Deleted
                <br />
                <small> Item Deleted </small>
              </h5>
              <p class="mb-1">
                {{ item.quantity }}
              </p>
              <p class="mb-1">{{ item.quantity * item.price }}€</p>
            </div>
          </a>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="fname" class="form-label"> Gesamt</label>
          <input
            disabled
            type="text"
            class="form-control"
            id="fname"
            :value="body.total + '€'"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="user" class="form-label">By User</label>
          <input
            type="text"
            class="form-control"
            id="user"
            :value="body.usersId.fname + ' ' + body.usersId.lname"
            disabled
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="note" class="form-label"> CartsArtikelList </label>
          <!-- <Button
            icon="pi pi-plus"
            class="p-button-info mr-2 ml-2"
            @click="body.adminnote.push({ note: '' })"
          />
          <Button
            icon="pi pi-minus"
            class="p-button-warning"
            @click="body.adminnote.length > 1 ? body.adminnote.pop() : null"
          /> -->

          <div class="row m-2 pb-3" v-for="(item, k) of body.cartsItemsList" :key="k" style="border-bottom: #ddd solid 1px;">
            <div class="col-md-6">
              <input
                class="form-control"
                id="productsId"
                name="productsId"
                v-model="item.productsId.pname"
                disabled
              />
            </div>
            <div class="col-md-3">
              <input
                class="form-control"
                id="quantity"
                name="quantity"
                v-model="item.quantity"
                disabled
              />
            </div>
            <div class="col-md-3">
              <input
                class="form-control"
                id="price"
                name="price"
                :value="item.quantity * item.price"
                disabled
              />
            </div>
          
            
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <!-- <Button
          label="Bearbeiten"
          icon="pi pi-pencil"
          class="ml-2 p-button-info"
          @click="update()"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        sex: "MR",
        fname: null,
        lname: null,
        phone: null,
        address: null,
        paymentMethod: true,
        zip: null,
        city: null,
        country: null,
        recieveType: "himself",
        userId: null,
      },
      id: null,
      user: null,

      list: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    updateBaytype() {
      this.$http
        .post(`gifts/updateOrders/${this.id}`, {
          paymentDone: false,
        })
        .then(
          () => {
            this.body.paymentDone = false;
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    updateDone() {
      this.$http
        .put(`carts/${this.id}`, {
          paymentDone: true,
        })
        .then(
          () => {
            this.body.paymentDone = true;
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    update() {
      this.$http
        .post(`gifts/updateOrders/${this.id}`, {
          adminnote: this.body.adminnote,
        })
        .then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
    },
    getUser(list) {
      let str = "";
      if (list && list.length > 0) {
        for (const [i, item] of list) {
          if (i == 0) {
            str = item.name;
          } else {
            str = str + " & " + item.name;
          }
        }
      }
      return str;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = Number(this.$route.params.id);
      this.$http.get(`carts/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
          // this.body.adminnote = res.data.data.adminnote
          // ? JSON.parse(res.data.data.adminnote)
          // : [{ note: '' }];

          // this.user = res.data.user;
          // this.list = res.data.list;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
  },
  // watch: {
  //   depoist(val) {
  //     if (val > 0) {

  //     }
  //   },
  // },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
